@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";

.loading-container {
	width: 100%;
	height: 20rem;
	max-height: 100vh;
	@include flex-container(column, center, center, nowrap);
}
.Pulsar-module_container__iLGP9 {
	height: 100%;
	width: 100%;
	@include themed() {
		color: t(var.$border);
	}
}
