@mixin flex-container($direction, $justify, $align, $wrap) {
	display: flex;
	flex-direction: $direction;
	align-items: $align;
	justify-content: $justify;
	flex-wrap: $wrap;
}

@mixin text($family, $size, $weight) {
	font-family: $family !important;
	font-size: $size !important;
	font-weight: $weight !important;
}
@mixin even-spacing($margin, $padding) {
	margin: $margin;
	padding: $padding;
}
@mixin standard-shadow() {
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
}
@mixin standard-shadow-top() {
	box-shadow: 0px -4px 5px -4px rgba(0, 0, 0, 0.4);
}
