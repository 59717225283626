@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
.be-heard-btn {
	@include themed() {
		background-color: t(var.$primary);
	}
	padding: 0.25rem;
	border-radius: 0.25rem;
	margin: 0.25rem 0;
	@include flex-container(row, center, center, nowrap);
	#be-heard-icon {
		margin: 0 0.25rem;
		color: var.$light;
	}
}
.be-heard-btn:hover {
	outline: 2px solid var.$primary-light;
	outline-offset: -2px;
}
.container {
	padding: 0.5rem 1.5rem;
	display: -ms-grid;
	display: grid;
	grid-template-areas: "menu be-heard profile";
	grid-template-columns: 1fr 5fr 1fr;
	gap: 1rem;
	align-items: center;
	.menu {
		grid-area: menu;
		.icon-btn {
			justify-self: flex-start;
			@include flex-container(column, center, center, nowrap);
			@include themed() {
				border: 1px solid t(var.$border);
				background-color: t(var.$fg);
			}
			border-radius: 50%;
			padding: 0.5rem;
		}
	}

	.be-heard-btn {
		grid-area: be-heard;
		justify-self: stretch;
		padding: 0.5rem 1rem;
		@include flex-container(row, center, center, nowrap);
		#be-heard-icon {
			margin: 0 0.25rem;
		}
	}
	#groups-btn:hover {
		@include themed() {
			border: 1px solid t(var.$border);
			background-color: var.$primary-light;
		}
	}

	.profile {
		justify-self: flex-end;
		grid-area: profile;
		margin-left: auto;
	}
}
