@use "../../var";
@use "../../theme";
@use "../../mixins";
.section-card {
	@include standard-shadow();
	@include themed() {
		background-color: t(var.$card);
	}
	border-radius: 0.5rem;
	padding: 1rem;
	.header {
		@include flex-container(row, flex-start, center, wrap);
		.right {
			margin-left: auto;
		}
	}
	.section-content {
		margin: 1rem 0;
	}
	&.testing {
		height: 70vh;
	}
}
.std-card {
	@include standard-shadow();
	@include themed() {
		background-color: t(var.$fg);
	}
	border-radius: 0.5rem;
	padding: 0.5rem;
	margin: 0.5rem 0;
	&.click {
		cursor: pointer;
	}
	.title {
		padding: 1rem;
	}
	.header {
		@include flex-container(row, flex-start, center, wrap);
		.right {
			margin-left: auto;
		}
	}
}
.card-type {
	height: 3rem;
	&.small {
		height: 2rem;
	}
	&.large {
		height: 4rem;
	}
}

.card-list-item {
	padding: 0.5rem;
	border-radius: 0.25rem;
	margin: 0.25rem 0;

	@include flex-container(row, flex-start, center, nowrap);
	width: 100%;

	&:hover {
		@include themed() {
			background-color: t(var.$fg);
		}
	}
	&.check-item {
		justify-content: space-between;
		&.selected {
			@include themed() {
				background-color: t(var.$border);
			}
			.checkbox {
				@include themed() {
					background-color: t(var.$primary);
				}
			}
		}
		&.unselected {
			.checkbox {
				@include themed() {
					color: t(var.$text-secondary);
				}
			}
		}
	}
	.avatar {
		margin-right: 0.5rem;
	}

	.icons {
		margin-left: auto;
		@include flex-container(row, flex-end, center, nowrap);
	}
	p {
		margin-left: 0.5rem;
	}
}
.card-list-item:hover {
	@include themed() {
		background-color: t(var.$fg);
	}
}
.card-list-item.outline {
	@include themed() {
		outline: 1px solid t(var.$border);
	}
	&:hover {
		@include themed() {
			background-color: t(var.$border);
		}
	}
}
.member-card {
	width: 100%;
	border-radius: 0.25rem;
	padding: 0.35rem 0.5rem;
	margin: 0.125rem auto;
	@include standard-shadow();
	@include flex-container(row, flex-start, center, nowrap);
	@include themed() {
		background-color: t(var.$fg);
		border: 0.5px solid t(var.$border);
	}
	.avatar {
		width: 3rem;
		height: 3rem;
	}
	.content {
		padding-left: 0.5rem;
	}
	.option-btn,
	.accept-btn,
	.remove-btn {
		margin-left: auto;
	}
	&.selected {
		color: var.$light;
		background-color: var.$primary-light;
		border: 1px solid var.$primary-dark;
	}
	&.single {
		margin: 0.5rem auto;
	}
}
@import "./communityCard";
@import "./feedCards";
