@use "../var";
@import "../colors";
@import "../mixins";
@import "../theme";
button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	text-align: inherit;
	&.pointer-none {
		cursor: default;
	}
}
.upload-btn {
	label {
		cursor: pointer;
		@include flex-container(column, center, center, nowrap);
	}
	padding: 0.5rem;
	border-radius: 50%;
	@include themed() {
		border: 1px solid t(var.$border);
		background-color: t(var.$fg);
	}
	.MuiIcon-root,
	.MuiSvgIcon-root {
		// @include themed(){
		// 	color
		// }
	}
	#file-input {
		display: none;
	}
}
.drawer-btn {
	width: 100%;
	padding: 0.5rem;
	@include flex-container(row, flex-start, center, nowrap);
	.MuiIcon-root,
	.MuiSvgIcon-root {
		margin-right: 0.5rem;
	}
}
.MuiButton-containedPrimary {
	p {
		color: var.$light;
	}
}

.button-group {
	width: 100%;
	&.left {
		@include flex-container(row, flex-start, center, wrap);
		float: left;
		button,
		.MuiButton-root {
			margin-right: 0.25rem;
		}
	}
	&.right {
		@include flex-container(row, flex-end, center, wrap);
		float: right;
		button,
		.MuiButton-root {
			margin-left: 0.25rem;
		}
	}
	&.center {
		@include flex-container(row, center, center, nowrap);
		button,
		.MuiButton-root {
			margin: 0 0.125rem;
		}
	}
}
.button-group-vertical {
	.MuiButton-root {
		margin: 0.25rem 0;
	}
}
