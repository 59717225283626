@use "../var";

.pt {
	padding-top: var.$default;
}
.pt-L {
	padding-top: var.$L;
}
.pb {
	padding-bottom: var.$default;
}
.pb-L {
	padding-bottom: var.$L;
}
.pr {
	padding-right: var.$default;
}
.pl {
	padding-left: var.$default;
}
.mt {
	margin-top: var.$default;
}
.mb {
	margin-bottom: var.$default;
}
.mt-L {
	margin-top: var.$L;
}
.ml-auto {
	margin-left: auto;
}
.mr {
	margin-right: var.$default;
}
.ml {
	margin-left: var.$default;
}
.ml-auto {
	margin-left: auto;
}
.mr-auto {
	margin-right: auto;
}
.w-full {
	width: 100%;
}
.inline {
	* {
		display: inline;
	}
}
.flex-start {
	@include flex-container(row, flex-start, center, nowrap);
}
.flex-between {
	@include flex-container(row, space-between, center, nowrap);
	width: 100%;
}
.align-start {
	align-items: flex-start;
}
.align-center {
	@include flex-container(column, center, flex-start, nowrap);
	width: 100%;
	text-align: center;
	* {
		text-align: center;
		margin: 0 auto;
	}
}
@media screen and (max-width: var.$screen_S) {
	.hidden-mobile {
		display: none !important;
	}
	.visible-mobile {
		display: block;
	}
}
@media screen and (min-width: var.$screen_S) {
	.hidden-mobile {
		display: block;
	}
	.visible-mobile {
		display: none !important;
	}
}
