@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
#app-header {
	header {
		display: -ms-grid;
		display: grid;

		padding: 1rem;
		.logo {
			height: 2rem;
		}
		.header-btn {
			height: 2.5rem;
			width: 2.5rem;
			margin: 0 0.5rem;
			border-radius: 50%;
			@include flex-container(column, center, center, nowrap);
			.MuiIcon-root {
				@include themed() {
					color: t(var.$text-secondary);
				}
			}
			&.no-margin {
				margin: 0;
			}
			transition: all 0.25s linear;
		}
		.header-btn:hover {
			@include themed() {
				background-color: t(var.$fg);
				border: 1px solid t(var.$border);
			}
			transition: all 0.25s linear;
		}
		.left {
			@include flex-container(row, flex-start, center, nowrap);
			.group {
				@include flex-container(row, flex-start, center, nowrap);
				.avatar {
					margin: 0 0.5rem;
				}
			}
		}
		.center {
		}
		.right {
			@include flex-container(row, flex-end, center, nowrap);
			.profile {
				height: 2.5rem;
				width: 2.5rem;
				margin: 0 0.5rem;
			}
		}
	}
}
@media screen and (min-width: 0) and (max-width: var.$screen_M) {
	//tablet
	#app-header {
		header {
			-ms-grid-columns: 1fr 1fr;
			grid-template-columns: 1fr 1fr;
			.left {
				-ms-grid-column: 1/2;
				grid-column: 1/2;
			}
			.right {
				-ms-grid-column: 2/3;
				grid-column: 2/3;
			}
			.center {
				display: none;
			}
		}
	}
}
@media screen and (min-width: var.$screen_M) {
	#app-header {
		header {
			-ms-grid-columns: 3fr 6.5fr 3fr;
			grid-template-columns: 3fr 6.5fr 3fr;
		}
		.left {
			-ms-grid-column: 1/2;
			grid-column: 1/2;
		}
		.right {
			-ms-grid-column: 3/4;
			grid-column: 3/4;
		}
		.center {
			-ms-grid-column: 2/3;
			grid-column: 2/3;
		}
	}
}
