@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
.MuiIcon-root,
.MuiSvgIcon-root {
	@include themed() {
		color: t(var.$text);
	}
	&.text {
		@include themed() {
			color: t(var.$text);
		}
	}
	&.secondary {
		@include themed() {
			color: t(var.$text-secondary);
		}
	}
	&.small {
		font-size: 1rem;
	}
	&.large {
		font-size: 2rem;
	}
	&.primary {
		@include themed() {
			color: t(var.$primary);
		}
	}
	&.icon-white {
		color: var.$light !important;
	}
	&.success {
		@include themed() {
			color: t(var.$success);
		}
	}
	&.danger {
		@include themed() {
			color: t(var.$danger);
		}
	}
	&.favorite {
		color: var.$yellow;
	}
	&.accent1 {
		color: var.$accent1-default;
	}
	&.accent2 {
		color: var.$accent2-default;
	}
}
.default-icon {
	width: 40px;
}
.stat-icon {
	@include flex-container(row, flex-start, center, nowrap);
	margin-right: 0.5rem;
	p,
	h6,
	h5 {
		vertical-align: center;
	}
	.MuiIcon-root,
	.MuiSvgIcon-root {
		margin: 0 0.25rem;
	}
}
