@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";

.custom-tabs {
	width: 100%;

	border-top-right-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
	@include themed() {
		border-bottom: 3px solid t(var.$primary);
	}
	@include flex-container(row, flex-start, center, nowrap);
	@include standard-shadow();
	@include themed() {
		background-color: t(var.$card);
	}
	.tab-btn {
		flex: 1;
		padding: 0.5rem;
		padding-top: 1rem;
		border-top-right-radius: 0.5rem;
		border-top-left-radius: 0.5rem;
		&:hover {
			@include themed() {
				background-color: t(var.$fg);
			}
		}
		&.focused {
			background-color: var.$primary-light-LO;
			border-radius: 0.5rem;
		}
		p,
		h5,
		h4,
		h3 {
			text-align: center;
		}
		&.large {
			min-height: 5rem;
		}
	}
}
