@use "../../var";
@import "../../mixins";
@import "../../colors";
@import "../../theme";
#history-buttons {
	@include flex-container(row, space-between, center, nowrap);
	margin: 0.5rem;

	border-radius: 0.5rem;
	height: 2rem;
	button {
		padding: 0.25rem;
		border-width: 0;
		border-color: transparent;
		display: flex;
		border-radius: 50%;
		transition: all 0.2s linear;
	}
	button:hover {
		@include themed() {
			background-color: t(var.$bg);
			outline: 1px solid t(var.$border);
		}
		transition: all 0.2s linear;
	}
}
