@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
.chakra-form-control {
	.chakra-form__label {
		margin-top: var.$default;
	}
	.chakra-input__group {
		.chakra-input__right-element {
			width: max-content;
		}
		.chakra-button {
			margin: 0;
		}
	}
	.chakra-button {
		margin-top: var.$default;
		width: 100%;
	}
}
.chakra-accordion {
	.chakra-accordion__item {
		.chakra-accordion__icon {
			margin-left: auto;
		}
	}
}
.chakra-popover {
	@include themed() {
		background-color: t(var.$fg);
	}
}
