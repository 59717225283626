@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";

.community-right {
	&.members {
		.member-card {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
		}
		.member-card:hover {
			cursor: pointer;
			@include standard-shadow();
			@include themed() {
				background-color: t(var.$border);
			}
		}
	}
}
