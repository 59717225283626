@use "var";
@import "./theme";
@import "./mixins";

// STYLE: TEXT
// <div>
// <h1>extra-big-title</h1>
// <h2>title</h2>
// <h3>sub-title</h3>
// <h4>bold medium title</h4>
// <h5>medium title</h5>
// <h6>sub-sub-title (small emphasis)</h6>
// <p>
// 	Lorem ipsum dolor, sit amet consectetur adipisicing elit.
// 	Praesentium quas quo quia itaque aspernatur maxime iusto rerum
// 	aperiam perspiciatis reiciendis repellendus ratione libero,
// 	commodi repudiandae tempore vitae nobis ipsa rem?
// </p>ß
// <p className="small"> wow small text</p>
// </div>
h1,
h2,
h3,
h4,
h5,
h6,
p {
	@include themed() {
		color: t(var.$text);
	}
	&.secondary {
		@include themed() {
			color: t(var.$text-secondary);
		}
	}
	&.link {
		@include themed() {
			color: t(var.$link);
		}
	}
	&.link:hover {
		text-decoration: underline;
		button {
			text-decoration: underline;
		}
	}
	&.underline-hover:hover {
		text-decoration: underline;
		button {
			text-decoration: underline;
		}
	}
	&.center {
		text-align: center;
	}
	&.left {
		text-align: left;
	}
	&.right {
		text-align: right;
	}
	&.white {
		color: var.$light;
	}
	&.black {
		color: var.$dark;
	}
	&.purple {
		@include themed() {
			background-color: t(var.$primary);
		}
	}

	&.danger {
		@include themed() {
			background-color: t(var.$danger);
		}
	}
	&.no-wrap {
		white-space: nowrap;
	}
}
a {
	@include text(var.$font-body, var.$default, 500);

	@include themed() {
		color: t(var.$link);
	}
}
h1 {
	@include text(var.$font-title, var.$XXL, var.$Bold-weight);
}

h2 {
	@include text(var.$font-title, var.$XL, var.$Medium-weight);
}

h3 {
	@include text(var.$font-title, var.$L, var.$Medium-weight);
}

h4 {
	@include text(var.$font-title, var.$M, var.$Medium-weight);
}
h5 {
	@include text(var.$font-body, var.$M, var.$Default-weight);
}
h6 {
	@include text(var.$font-title, var.$button, var.$Medium-weight);
}

p,
.ce-paragraph {
	@include text(var.$font-body, var.$default, var.$Default-weight);
	@include themed() {
		color: t(var.$text);
	}
}
p.bold {
	@include text(var.$font-body, var.$default, var.$SemiBold-weight);
}

p.small {
	@include text(var.$font-body, var.$S, var.$Light-weight);
}

.slicedID {
	opacity: 60%;
}
