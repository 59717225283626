@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
// create poll, proposal, announcement, and initiatives screens
.write-proposal-wrapper,
.create-post-component {
	.text-field-wrapper {
		// .MuiTextField-root {
		// 	.MuiInputBase-root {
		// 		.MuiInputBase-input {
		// 			border-color: red !important;
		// 		}
		// 	}
		// }
		.button-group {
			.MuiButton-containedSecondary,
			.MuiButton-outlinedSecondary {
				@include themed() {
					background-color: t(var.$fg);
					color: t(var.$text-secondary);
					border-color: t(var.$border);
				}
				&:hover {
					@include themed() {
						background-color: t(var.$fg);
						color: t(var.$text);
						border-color: t(var.$text-secondary);
					}
				}
			}
		}
		.MuiFilledInput-root {
			@include themed() {
				background-color: t(var.$fg);
				border-color: t(var.$border) !important;
			}
		}

		// .MuiOutlinedInput-input:hover {
		// 	@include themed() {
		// 		border-color: t(var.$border);
		// 	}
		// }
	}
	.set-poll-options {
		.MuiButton-textSecondary {
			@include themed() {
				border: 2px solid t(var.$border);
				color: t(var.$text-secondary);
			}
		}
		.text-field-wrapper {
			.MuiFilledInput-root {
				@include themed() {
					background-color: t(var.$fg);
					border-color: t(var.$border) !important;
				}
			}
		}
	}
}
.create-post-component {
	padding: 1rem;
	@include themed() {
		border: 1px solid t(var.$border);
		border-radius: 0.5rem;
	}
}

.create-post,
.create-post-component {
	.set-poll-options {
		padding-top: 1rem;

		.header {
			@include flex-container(row, space-between, center, nowrap);
		}
	}
	.option-container {
		h3 {
			padding: 1rem 0;
		}
		p {
			margin: 0.5rem;
		}
		.option {
			padding: 0.5rem;
			margin: 0.25rem 0;
			border-radius: 0.25rem;
			width: 100%;
			@include flex-container(row, space-between, center, nowrap);
			@include themed() {
				background-color: t(var.$fg);
			}
			.buttons {
				@include flex-container(row, flex-end, center, nowrap);
				button {
					margin-left: 0.5rem;
				}
			}
		}
	}
	.select-poll-type {
		.header {
			@include flex-container(row, flex-start, center, nowrap);
		}
		.poll-type {
			width: 100%;
			border-radius: 0.5rem;
			.content {
				padding: 1rem;
				@include flex-container(row, flex-start, center, nowrap);
				.text {
					margin-left: 1rem;
					@include flex-container(column, flex-start, flex-start, nowrap);
				}
			}
			&:hover {
				@include themed() {
					background-color: t(var.$bg);
					outline: 1px solid t(var.$border);
					outline-offset: -2px;
				}
			}
		}
	}
}
