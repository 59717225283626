@use "../var";
@use "../theme";
@import "../mixins";
#app-center {
	padding-bottom: 10rem;
	overflow-y: scroll;
	overflow-x: visible;
	padding: 0.5rem;
}
#app-right {
	overflow: scroll;
	padding: 0.5rem;
}
#app-left {
	overflow: scroll;
	padding: 0.5rem;
}
@media only screen and (max-width: var.$screen_S) {
	//mobile
	#app-container {
		-ms-grid-rows: 4rem 1fr;
		-ms-grid-columns: 1fr;
		grid-template-rows: 4rem 1fr;
		grid-template-columns: 1fr;
	}
	#app-header {
		-ms-grid-row: 1/2;
		-ms-grid-column: 1/2;
		grid-row: 1/2;
		grid-column: 1/2;
	}
	#app-center {
		-ms-grid-row: 2/3;
		-ms-grid-column: 1/2;
		grid-row: 2/3;
		grid-column: 1/2;

		max-width: 100vw;
		max-height: calc(100vh - 4rem);
		padding: 0 1rem;
		padding-bottom: 20rem;
	}
	#app-left {
		display: none;
	}
	#app-right {
		display: none;
	}
	#mobile-footer {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 99;
	}
}
@media screen and (min-width: var.$screen_S) and (max-width: var.$screen_M) {
	//tablet
	#app-container {
		-ms-grid-rows: 4rem auto;
		-ms-grid-columns: 1fr 3fr;
		grid-template-rows: 4rem auto;
		grid-template-columns: 1fr 3fr;
	}
	#app-header {
		-ms-grid-row: 1/2;
		-ms-grid-column: 1/3;
		grid-row: 1/2;
		grid-column: 1/3;
	}
	#app-center {
		-ms-grid-row: 2/3;
		-ms-grid-column: 2/3;
		grid-row: 2/3;
		grid-column: 2/3;
		padding-bottom: 20rem;
		padding-right: 1rem;
	}
	#app-left {
		-ms-grid-row: 2/4;
		-ms-grid-column: 1/2;
		grid-row: 2/4;
		grid-column: 1/2;
	}
	#app-right {
		// -ms-grid-row: 2/3;
		// -ms-grid-column: 2/3;
		// grid-row: 2/3;
		// grid-column: 2/3;
		// max-height: 4rem;
		// overflow: hidden;
		display: none;
	}
	#mobile-footer {
		display: none;
	}
}

@media screen and (min-width: var.$screen_M) {
	//computer and beyond
	#app-container {
		-ms-grid-rows: 4rem 1fr;
		-ms-grid-columns: 3fr 6.5fr 3fr;
		grid-template-rows: 4rem 1fr;
		grid-template-columns: 3fr 6.5fr 3fr;
		padding: 0 1rem;
	}
	#app-header {
		-ms-grid-row: 1/2;
		-ms-grid-column: 1/4;
		grid-row: 1/2;
		grid-column: 1/4;
	}
	#app-center {
		-ms-grid-row: 2/3;
		grid-row: 2/3;
		-ms-grid-column: 2/3;
		grid-column: 2/3;
	}
	#app-left {
		-ms-grid-row: 2/3;
		grid-row: 2/3;
		-ms-grid-column: 1/2;
		grid-column: 1/2;
		height: 100%;
		overflow: visible;
	}
	#app-right {
		-ms-grid-row: 2/3;
		grid-row: 2/3;
		-ms-grid-column: 3/4;
		grid-column: 3/4;
	}
	#mobile-footer {
		display: none;
	}
}
