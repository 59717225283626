@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
@media screen and (max-width: var.$screen_S) {
	#home-left {
		max-width: 70vw;
	}
}
#home-left {
	@include flex-container(column, flex-start, center, nowrap);
	.group-list-container {
		width: 100%;
		height: 10%;
		flex: 1;
		overflow: scroll;
		overflow-x: hidden;
		.group-list-wrapper {
			height: 90%;
			margin-bottom: 1rem;
			padding-bottom: 10rem;
			@include flex-container(column, flex-start, flex-start, nowrap);
		}
	}
}
@import "./left/profileLeft";
