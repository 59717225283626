$bg: "bg";
$fg: "fg";
$card: "card";
$text: "text";
$text-secondary: "text-secondary";
$border: "border";
$link: "link";
$primary: "primary";
$danger: "danger";
$success: "success";

$primary-default: #6840d9;
$primary-light: #8f67ff;
$primary-dark: #3c009f;
$accent1-default: #55bdde;
$accent1-light: #8ed6ff;
$accent1-dark: #0085be;
$accent2-default: #f41690;
$accent2-light: #ff61c0;
$accent2-dark: #bc0063;

$yellow: #c7be55;
$yellow-LO: #fff70770;
$success-LO: #3bed6b70;
$primary-light-LO: #8f67ff70;
$accent2-light-LO: #ff61c070;
$accent1-light-LO: #8ed6ff70;
$primary-light-ULO: #8f67ff20;
$accent2-light-ULO: #ff61c020;
$accent1-light-ULO: #8ed6ff20;

$light: #fcfcfc;
$dark: #151515;

$gradient-disabled: "gradient-disabled";
$gradient-logo: "gradient-logo";
$gradient-primary: "gradient-primary";
$gradient-accent1: "gradient-accent1";
$gradient-accent2: "gradient-accent2";

$font-title: "Open Sans", sans-serif;
$font-body: "Helvetica", sans-serif;

$XXL: 2.5rem;
$XL: 2rem;
$L: 1.5rem;
$M: 1.25rem;
$button: 1.15rem;
$default: 1rem;
$S: 0.75rem;
$XS: 0.5rem;

$Bold-weight: 900;
$SemiBold-weight: 700;
$Medium-weight: 500;
$Default-weight: 400;
$Light-weight: 300;

$screen_S: 480px;
$screen_M: 768px;
$screen_L: 1200px;
$screen_XL: 1600px;
