@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";

.codex-editor {
	z-index: 2;
	position: relative;
	padding: 1rem;
	flex: 1;
	width: 100%;
}
.write-proposal-wrapper {
	padding: 1rem;
	position: fixed;
	width: 100vw;
	left: 0;
	right: 0;
	overflow-y: scroll;
	height: 100%;
	padding-bottom: 30rem;
	@include flex-container(column, flex-start, center, nowrap);
	&::-webkit-scrollbar {
		width: 10px;
		height: 100%;
	}
	&::webkit-scrollbar-track {
		@include themed() {
			box-shadow: inset 0 0 10px 10px t(var.$border);
		}
		border: solid 3px transparent;
	}
	&::-webkit-scrollbar-thumb {
		@include themed() {
			box-shadow: inset 0 0 10px 10px var.$primary;
		}
		border: solid 3px transparent;
	}
	.proposal-header {
		width: 100%;
		max-width: 50rem;
	}
}
.write-proposal {
	height: 100%;
	margin: 0 auto;
}
.ce-toolbar__settings-btn,
.ce-toolbar__plus,
.ce-toolbox__button,
.ce-inline-toolbar,
.ce-inline-toolbar--showed,
.ce-inline-toolbar--left-oriented {
	border-radius: 0.25rem;
	@include themed() {
		color: t(var.$text-secondary);
	}
	&:hover {
		@include themed() {
			color: t(var.$text);
			background-color: t(var.$fg);
		}
	}
}
.ce-tooltip {
	background-color: red;
}
.ce-toolbar,
.ce-toolbox__button--active,
.ce-inline-tool {
	@include themed() {
		color: t(var.$text);
		background-color: t(var.$fg);
	}
}

.ce-conversion-toolbar {
	.ce-conversion-tool,
	.ce-conversion-toolbar__label {
		@include text(var.$font-body, var.$default, var.$Default-weight);
	}
}
.ce-inline-toolbar {
	@include themed() {
		color: t(var.$text-secondary);
		background-color: t(var.$fg);
		border: 1px solid t(var.$border);
	}
	.ce-inline-toolbar__toggler-and-button-wrapper {
		.ce-inline-tool,
		.ce-inline-toolbar__dropdown {
			@include themed() {
				color: t(var.$text);
				background-color: t(var.$fg);
			}
			&:hover {
				@include themed() {
					color: t(var.$text);
					background-color: t(var.$bg);
				}
			}
		}
	}
}

.tc-popover {
	@include themed() {
		color: t(var.$text);
		background-color: t(var.$fg);
		border: 1px solid t(var.$border);
	}
	.item-icon {
		@include themed() {
			color: t(var.$text);
		}
	}
}
.tc-row--selected,
.tc-column--selected,
.tc-add-row,
.tc-add-column {
	@include themed() {
		background-color: t(var.$fg);
	}
}

.tc-add-column--active {
	@include themed() {
		background-color: t(var.$fg);
	}
}
.ce-inline-tool {
	@include themed() {
		color: t(var.$text);
	}
}
.cdx-nested-list__item-content,
.cdx-nested-list__item::before {
	font-family: var.$font-body;
}
.ce-block--selected,
.ce-block--focused {
	.walkthrough-timeline {
		background-color: var.$primary-light-ULO;
	}
	.cdx-block,
	.ce-block-content {
		background-color: var.$primary-light-ULO;
	}
}
.ce-settings--opened {
	@include themed() {
		background-color: t(var.$fg);
		border-color: t(var.$border);
	}
	.ce-settings__button,
	.cdx-settings-button {
		@include themed() {
			color: t(var.$text);
		}
		&:hover {
			@include themed() {
				background-color: t(var.$border);
			}
		}
	}
	.cdx-settings-button--active {
		background-color: var.$primary-light-LO;
	}
}
