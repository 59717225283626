@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
.close-modal {
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
}
.modal {
	z-index: 100;
	&.modal-wrapper::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		-webkit-filter: blur(5px);
		-moz-filter: blur(5px);
		-o-filter: blur(5px);
		-ms-filter: blur(5px);
		filter: blur(5px);
		@include themed() {
			background-color: t(var.$bg);
			opacity: 0.1;
		}
	}
	&.modal-wrapper {
		position: fixed;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		z-index: 90;
		@include flex-container(row, center, center, nowrap);
	}
	&.small-modal-wrapper {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		right: 0;
	}

	&.hidden {
		display: none;
	}
	.content {
		z-index: 100;
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 0.5rem;
		border-radius: 0.25rem;
		@include standard-shadow();

		@include themed() {
			border: 1px solid t(var.$border);
			background-color: t(var.$fg);
		}
	}
}
