@import "./colors";

$themes: (
	default: (
		bg: $light--background,
		fg: $light--foreground,
		card: $light--card,
		text: $light--text,
		text-secondary: $light--text-secondary,
		border: $light--border,
		link: $light--link,
		primary: $light--primary,
		danger: $light--danger,
		success: $dark--success,
	),
	dark: (
		bg: $dark--background,
		fg: $dark--foreground,
		card: $dark--card,
		text: $dark--text,
		text-secondary: $dark--text-secondary,
		border: $dark--border,
		link: $dark--link,
		primary: $dark--primary,
		danger: $dark--danger,
		success: $dark--success,
	),
);

@mixin themed() {
	@each $theme, $map in $themes {
		.theme--#{$theme} & {
			$theme-map: () !global;
			@each $key, $submap in $map {
				$value: map-get(map-get($themes, $theme), "#{$key}");
				$theme-map: map-merge(
					$theme-map,
					(
						$key: $value,
					)
				) !global;
			}
			@content;
			$theme-map: null !global;
		}
	}
}

@function t($key) {
	@return map-get($theme-map, $key);
}
