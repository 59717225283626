@use "../var";
@import "../colors";
@import "../mixins";
@import "../theme";
.toast {
	@include themed() {
		background-color: t(var.$fg);
		color: t(var.$text);
		border: 1px solid t(var.$border);
	}
	font-family: "Red Hat Display", sans-serif;
}
.open-in-app {
	@include flex-container(row, center, center, nowrap);
	padding: 1rem;
}
.inbox {
	.community-invite {
		width: 100%;
		@include flex-container(row, space-between, center, nowrap);
		.group-card {
			flex: 1;
			margin-right: 1rem;
		}
	}
}
.stat-container {
	width: 100%;
	padding: 1rem;

	.stat {
		flex: 1;
		margin: 0.5rem auto;
		text-align: center;
		@include flex-container(row, space-between, center, wrap);
		@include themed() {
			border-bottom: 1px dotted t(var.$border);
		}
	}
}
.preferences-container {
	width: 100%;
	border-radius: 0.5rem;
	overflow: hidden;
	padding: 0.125rem;
	margin: 0.25rem 0;

	.preference {
		width: 100%;
		border-radius: 0.25rem;
		padding: 0.75rem;
		margin: 0.25rem 0;
		@include flex-container(row, space-between, flex-start, nowrap);
		position: relative;
		.MuiSwitch-root {
			position: absolute;
			right: 0;
			top: 0;
			margin-top: 0.25rem;
		}
	}
	.preference:hover {
		@include themed() {
			background-color: t(var.$fg);
			outline: 1px solid t(var.$border);
		}
	}
}
.text-field-wrapper {
	margin: 0.75rem 0rem;
	flex: 1;
	width: 100%;
	@include flex-container(column, flex-start, center, nowrap);
	.label {
		// z-index: 1;
		@include themed() {
			color: t(var.$text-secondary);
		}
	}
	.input {
		margin-bottom: 0.5rem;
		width: 100%;
		@include themed() {
			color: t(var.$text);
		}
	}
	.primary {
		@include themed() {
			background-color: t(var.$fg);
			color: t(var.$text);
		}
	}
	.password {
		-webkit-text-security: disc;
	}
	.bg {
		@include themed() {
			color: t(var.$text);
		}
	}
	.fg {
		@include themed() {
			background-color: t(var.$fg);
		}
	}

	.text-field {
		width: 100%;
		margin: 0.5rem;
		padding: 1rem;
		@include themed() {
			background-color: t(var.$bg);
			color: t(var.$text);
		}
	}
	.input-text {
		@include themed() {
			color: t(var.$text);
		}
	}
	.input-placeholder {
		@include themed() {
			color: t(var.$text);
		}
	}
	.footer {
		width: 100%;
		@include flex-container(row, center, center, nowrap);
	}
	&.tight {
		margin: 0 auto;
		.text-field {
			margin: 0;
		}
		.input {
			margin-bottom: 0;
		}
	}
	&.row {
		padding: 0.5rem 0;
		@include flex-container(row, center, center, nowrap);
	}
}
