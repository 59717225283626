@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
.profile-settings {
	.bottom {
		height: 100vh;
		width: 100%;
	}
	.section-card {
		margin-bottom: 0.5rem;
	}
}
#profile-settings-component {
	.header {
		@include flex-container(column, flex-start, center, nowrap);
		&.profile {
			padding-bottom: 3rem;
		}
		.avatar-container {
			width: 10rem;
			height: 10rem;
			margin: 1rem auto;
			position: relative;
			.avatar {
				width: 100%;
				height: 100%;
			}
			.upload-btn {
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}
	}
}
#group-settings {
	.header {
		margin: 2rem 1rem;
		@include flex-container(row, center, center, wrap);
		.avatar-container {
			margin: 1rem 2rem;
		}
	}
	.invite-members {
		.invite-container {
			@include flex-container(row, flex-start, center, nowrap);
		}
	}
	.editing {
		@include flex-container(column, center, center, nowrap);
	}
}
.email-card {
	width: 100%;
	@include flex-container(row, space-between, center, nowrap);
}
