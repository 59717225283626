@use "./var";
@use "./colors";
@use "./typography";
@import "./theme";
h1,
h2,
h3,
h4,
h5,
h6,
p {
	padding: 0;
	margin: 0;
}
* {
	box-sizing: border-box;
	font-size: 14px;
}
html {
	height: 100vh;
	max-height: 100vh;
	width: 100vw;
}
#root-wrapper {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	@include themed() {
		background-color: t(var.$bg);
		color: t(var.$text);
	}
}
#app-container {
	height: 100%;
	max-height: 100vh;
	max-width: 90rem;
	margin: 0 auto;
	display: -ms-grid;
	display: grid;
	z-index: 1;
}
#app-center,
#app-left,
#app-right,
.scroll-auto,
.infinite-scroll-component {
	&::-webkit-scrollbar {
		width: 1px;
		height: 100%;
	}
	&::webkit-scrollbar-track {
		@include themed() {
			box-shadow: inset 0 0 10px 10px t(var.$border);
		}
		border: solid 3px transparent;
	}
	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 10px 10px transparent;
		// box-shadow: inset 0 0 10px 10px var.$primary-light-LO;
		border: solid 3px transparent;
	}
}
#app-center:hover,
#app-left:hover,
#app-right:hover,
.scroll-auto:hover {
	&::-webkit-scrollbar-thumb {
		@include themed() {
			box-shadow: inset 0 0 10px 10px var.$primary;
		}
	}
}
::selection {
	@include themed() {
		background: t(var.$primary);
	}
}
.scroll-auto {
	height: 100%;
}
li {
	list-style-type: none;
}
//core app components
@import "./core/appLayout";
@import "./core/header";
@import "./core/appLeft";
@import "./core/utility";
@import "./core/appRight";
@import "./core/chakra";

//screen / focus areas
@import "./focus/community";
@import "./focus/beHeard";
@import "./focus/createPost";
@import "./focus/settings";
//components
@import "./components/components";
@import "./components/buttons";
@import "./components/icons";
@import "./components/avatars";
@import "./components/cards";
@import "./components/modal";
@import "./components/loading";
