@use "../../var";
@import "../../mixins";
@import "../../colors";
@import "../../theme";

.group-card {
	@include flex-container(row, space-between, center, nowrap);
	padding: 0.25rem 0.5rem;
	margin: 0.5rem 0;
	width: 100%;
	border-radius: 0.25rem;
	&:hover {
		@include themed() {
			outline: 1px solid t(var.$border);
		}
	}
	&.selected {
		@include themed() {
			border: 1px solid t(var.$primary);
		}

		background-color: var.$primary-light-LO;

		.icon-container {
			.icon {
				padding: 0.5rem;
				@include themed() {
					color: t(var.$text);
				}
			}
		}
	}

	.content {
		@include flex-container(row, flex-start, center, nowrap);
		.MuiCheckbox-root {
			margin: 0;
			padding: 0 0.25rem 0 0;
		}
		.avatar-wrapper {
			position: relative;
			.avatar {
				height: 2rem;
				width: 2rem;
			}
			.admin-indicator {
				position: absolute;
				right: 0;
				bottom: 0;
				height: 0.75rem;
				width: 0.75rem;
				border-radius: 50%;
				@include themed() {
					border: 1px solid t(var.$bg);
					background-color: t(var.$primary);
				}
			}
		}
		p {
			margin: 0 0.75rem;
			text-align: left;
		}
	}

	.icon-container {
		@include flex-container(row, flex-end, center, nowrap);
	}
	.icon {
		padding: 0.5rem;
		@include themed() {
			color: t(var.$border);
		}
	}
	.icon:hover {
		transform: scale(1.2);
		@include themed() {
			color: t(var.$text);
		}
	}
	&:hover {
		@include themed() {
			background-color: t(var.$border);
		}

		.icon {
			@include themed() {
				color: t(var.$text);
			}
		}
	}
}
.sub {
	width: 95%;
	float: right;
	@include themed() {
		border-left: 1px solid t(var.$border);
	}
}
