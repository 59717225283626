@use "../var";
@import "../mixins";
@import "../colors";
@import "../theme";
.posting-as {
	width: 100%;
	.selected {
		width: 100%;
	}
}
.be-heard-screen {
	height: 100%;
	width: 100%;

	.drafts {
		.draft-card {
			margin: 0.5rem 0;
			overflow: hidden;
			width: 100%;
			@include flex-container(row, flex-start, center, wrap);
			p,
			h6 {
				padding-left: 1rem;
			}
			.edit {
				opacity: 0.3;
				margin-left: auto;
			}
			&:hover {
				.edit {
					opacity: 1;
				}
			}
		}
	}
}
