@use "../../var";
@import "../../mixins";
@import "../../colors";
@import "../../theme";

#profile-card {
	width: min(100%, 40rem);
	@include flex-container(column, flex-start, flex-start, nowrap);
	.options {
		margin-left: auto;
	}
	.avatar {
		height: 8rem;
		width: 8rem;
		margin: 1rem auto;
	}
	.loader {
		height: 8rem;
		width: 8rem;
		margin: 1rem auto;
	}

	.info {
		margin: 1rem auto 0;
		width: 100%;
		.stat {
			padding: 0.25rem;
			width: 100%;
			@include flex-container(row, space-between, center, wrap);
			@include themed() {
				border-bottom: 1px dotted t(var.$border);
			}
		}
		.tokens {
			.coin {
				height: 2rem;
				width: 2rem;
				margin-right: 0.5rem;
			}
			@include flex-container(row, flex-start, center, nowrap);
		}
	}
}
