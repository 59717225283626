@use "../../var";
@import "../../mixins";
@import "../../colors";
@import "../../theme";

.card-wrapper {
	width: min(100%, 45rem);
	margin: 0.25rem auto;
}
.card {
	width: min(100%, 45rem);
	border-radius: 0.5rem;
	position: relative;
	margin: 0 auto;
	padding: 0.5rem 1rem;
	@include standard-shadow();
	@include themed() {
		background-color: t(var.$card);
	}
}
// HEADERS
.discussion-header {
	.card-header {
		.default-header {
			.picture {
				width: 2rem;
				height: 2rem;
				margin: 0.25rem;
			}
		}
	}
}
.card-header {
	width: 100%;
	@include flex-container(row, flex-start, center, nowrap);
	.picture {
		width: 3.5rem;
		height: 3.5rem;
		margin: 0.25rem;
	}
	.text {
		@include flex-container(row, space-between, center, nowrap);
		width: 100%;
		.left {
			flex: 1;
			margin-left: 0.35rem;
			width: 100%;
		}
		.right {
			align-items: flex-start;
			justify-content: flex-end;
			@include flex-container(column, center, flex-end, nowrap);
			.badges {
				@include flex-container(row, flex-end, center, nowrap);
				.card-type {
					z-index: 3;
					border-radius: 50%;
				}
				.vote-check {
					z-index: 1;
					height: 2rem;
					width: 2rem;
					border-radius: 50%;
					@include standard-shadow();
				}
			}
		}
	}
}

.initiative-header {
	@include flex-container(row, space-between, center, wrap);
	.title {
		text-align: left;
		vertical-align: top;
	}
	.card-header {
		width: 50%;
	}
}
// CONTENT
.card-body {
	margin: 0.5rem;
	.toggle-decision {
		@include flex-container(row, flex-end, center, nowrap);
	}
	.option {
		flex: 1;
		margin: 0.25rem 0;
		overflow: hidden;
		width: 100%;
		position: relative;
		border-radius: 0.25rem;
		@include flex-container(row, space-between, center, nowrap);
		@include themed() {
			border: 2px solid t(var.$border);
		}
		.option-text {
			flex: 1;
			width: 100%;
			padding-left: 0.5rem;
		}
		p {
			z-index: 2;
			padding: 0.25rem;
		}
		.result {
			margin: 0.5rem 1rem;
		}
		.percentage-bar {
			// @include themed() {
			// 	opacity: 0.7;
			// }
			height: 6rem;
			padding: 1rem;
			position: absolute;
		}
		&.not-voted:hover {
			@include themed() {
				border: 2px solid t(var.$text);
			}
		}
		&.selected {
			@include themed() {
				border: 2px solid t(var.$primary);
			}
		}
	}
	&.proposal {
		.title {
			padding-bottom: 0.5rem;
		}
	}
	&.initiative {
		padding: 0.5rem 0;
	}
}
// FOOTER
.card-footer {
	@include flex-container(row, space-between, center, nowrap);
	overflow: hidden;
	padding: 0 0.5rem;
	height: 1.75rem;
	.item {
		height: 100%;
		flex: 1;
		padding: 0.25rem;
		border-radius: 0.25rem;
		@include flex-container(row, center, center, nowrap);
		.MuiSvgIcon-root,
		p {
			@include themed() {
				color: t(var.$text-secondary);
			}
			font-size: 1.125rem;
		}

		&:hover {
			@include themed() {
				background-color: t(var.$fg);
			}
			.MuiSvgIcon-root,
			p {
				@include themed() {
					color: t(var.$text);
				}
			}
		}
		&.voted {
			.MuiSvgIcon-root,
			p {
				@include themed() {
					color: t(var.$primary);
				}
				font-size: 1.125rem;
			}
		}
	}
}
.support-proposal.focused {
	margin: 0.5rem 0;
}
// DISCUSSIONS
.discussions {
	width: clamp(10rem, 100%, 45rem);
	margin: 0 auto;
}
.discussion-card {
	margin: 0.25rem 0;
	.card-header {
		.picture {
			width: 2.5rem;
			height: 2.5rem;
			margin: 0.25rem;
		}
	}
}
.sub-discussion {
	.discussion-card {
		margin: 0;
		border-color: transparent;
		border-radius: 0;
		box-shadow: none;
		@include themed() {
			border-left: 1px solid t(var.$border);
		}
	}
}
// FOCUSED
.initiative-screen {
	.header {
		@include flex-container(row, flex-start, center, wrap);
		padding: 0.5rem 0;
		.date,
		.MuiButton-root {
			margin-left: auto;
		}
	}

	.members-component {
		.member-card-container {
			width: 100%;
		}
		.header {
			.loading-wheel-container {
				height: 100%;
			}
		}
		.shadow-card {
			.leaders,
			.supporters {
				flex: 1;
				@include flex-container(row, flex-start, center, nowrap);
				.member-card-container {
					flex: 1;
				}
				.promote-btn,
				.demote-btn {
					padding: 0 0.5rem;
				}
				.demote-btn {
					transform: rotate(180deg);
				}
			}
		}
	}
	.shadow-card {
		padding: 0.5rem;
	}
	.messages {
		.shadow-card {
			width: 100%;
		}
		.MuiTextField-root {
			padding-bottom: 0;
		}
	}
}
.focus-proposal {
	.proposal-content {
		margin: 0 auto;
		.block {
			margin: 1rem auto;
			.delimiter {
				@include flex-container(column, center, center, nowrap);
			}
			.card-wrapper {
				width: 100%;
				margin: 3rem auto;
				@include flex-container(column, center, center, nowrap);
			}
		}
	}
}

// .top-banner {
// 	padding-bottom: 0.5rem;
// 	.initiative-banner {
// 		background-color: var.$urgent;
// 		padding: 0.25rem 1rem;
// 		width: 100%;
// 		max-height: 30px;
// 		border-radius: 0.25rem;
// 		margin: 0 auto;
// 	}
// 	.pinned {
// 		padding: 0.25rem 0;
// 		p {
// 			padding-left: 0.5rem;
// 		}
// 	}
// }
